<template>
  <div class="user-filter">
    
    <MultiSelect
        :options="fields"
        v-model="selectedFields" 
        placeholder="Filtrar Usuários"
        optionValue="value"
        optionLabel="label"
        class="filter mb-2"
    />

    <div class="filter-container mb-2">
        <div class="filter" v-if="selectedFields.includes('name')">
            <InputText placeholder="Nome" v-model="filter.name" required="true" autofocus id="name"/>
        </div>

        <div class="filter" v-if="selectedFields.includes('email')">
            <InputText placeholder="Email" v-model="filter.email" required="true" autofocus id="email"/>
        </div>

        <div class="filter" v-if="selectedFields.includes('cpf')">
            <InputText placeholder="Cpf" v-model="filter.cpf" required="true" autofocus id="cpf"/>
        </div>

        <div class="filter" v-if="selectedFields.includes('cep')">
            <InputText placeholder="Cep" v-model="filter.cep" required="true" autofocus id="cep"/>
        </div>

        <div class="filter" v-if="selectedFields.includes('phone')">
            <InputText placeholder="Telefone" v-model="filter.phone" required="true" autofocus id="phone"/>
        </div>

        <div class="filter" v-if="selectedFields.includes('city')">
            <InputText placeholder="Cidade" v-model="filter.city" required="true" autofocus id="city"/>
        </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'UserFilter',
   
    watch: {
        filter: {
            handler(val) {
                this.$emit("updateFilter", { filter: val });
            },
            deep: true 
        }
    },

    methods: {
       /*  setSelected(index, animal) {
            element.classList.toggle("selected");
            this.$emit("setAnimal", { item: animal.grupo });
        }, */
    },

    data() {
        return {
            filter: {},

            selectedFields: [],
            fields: [
                {label: 'Nome', value: 'name'},
                {label: 'Email', value: 'email'},
                {label: 'Cpf', value: 'cpf'},
                {label: 'Cep', value: 'cep'},
                {label: 'Telefone', value: 'phone'},
                {label: 'Cidade', value: 'city'},
            ]
        }
    }
}
</script>

<style>
    .filter-container {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .p-inputtext {
        width: 100%;
    }

    .filter {
		min-width: 195px;
	}

    @media (max-width: 576px) {
        .filter {
            width: 100%;
        }
  }
</style>