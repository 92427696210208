import { addDays, format, /* getUnixTime */ } from "date-fns";
import store from "../store/store";
import router from '../router'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment'

export default {
    methods: {
        exportExcel(filename = 'nome arquivo') {
            var downloadLink;
            var dataType = 'application/vnd.ms-excel';
            var tableSelect = document.querySelector('table');
            var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

            filename = filename?filename+'.xls':'excel_data.xls';
            downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            if(navigator.msSaveOrOpenBlob) {
                var blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
                downloadLink.download = filename;
                downloadLink.click();
            }

        },

        exportPDF(title = 'Relatório', period) {
            // Captura a tabela
            var table = document.querySelector('.p-datatable-table');
            var td = document.querySelector('.p-datatable-tbody');
                td.querySelectorAll('.p-column-title').forEach(function(el) {
                el.remove();
            });
        
            // Cria o documento pdf
            var doc = new jsPDF('l', 'pt', 'a4');
        
            // Define a data da impressão
            var date = new Date();
        
            var pdfTitle = period ? `${title} - ${period}` : `${title}`

            try {
                doc.autoTable({
                    html: table,
                    addPageContent: function(data) {
                        doc.setFontSize(20)
                        doc.text(pdfTitle, data.settings.margin.left, 30);
                        doc.setFontSize(14)
                        doc.text("Impresso em: " + date.toLocaleString('pt-BR'), data.settings.margin.left, 570);
                        doc.text("Página " + data.pageNumber + " de " + data.pageCount, data.settings.margin.left + 680, 570);
                    }
                });
            } catch (error) {
                console.log(error)
            }
           
            doc.output('dataurlnewwindow');
        },

        // DO LOGOUT 
        logout() {
            store.dispatch('setLoggout', {
                user: {},
                token: "",
                isLogged: false,
                expire: null
            });

            router.push({
                path: '/login'
            });
        },      

        // FORMAT DATE 
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY')
        },

        formatDateFull(date) {
            if (!date) return null;

            let new_date = addDays(new Date(date), 0);
            let edit_date = format(new_date, 'dd/MM/yyyy HH:mm');
            return edit_date;
        },

        formatDateDays(data) {
            const dataFornecida = moment(data, 'YYYY-MM-DD');
            const dataAtual = moment();

            const diferencaEmDias = dataAtual.diff(dataFornecida, 'days');

            if (diferencaEmDias === 0) {
                return 'hoje';
            } else if (diferencaEmDias === 1) {
                return 'ontem';
            } else {
                return diferencaEmDias + ' dias';
            }
        },

        formatDateDayOnly(date) {
            return moment(date).format('DD')
        },

        formatTime(time) {
            if (!time) return null;
            
            let new_time = new Date(time);
            let edit_time = new_time.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            return edit_time;
        },

        formatDateHour(date) {
            return moment(date).format('DD/MM/YYYY HH:mm')
        },

        formatCurrency(value) {
            if(!value) value = 0
            return parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2});
        },

        // PORCENTAGE FORMAT
        formatPorcentage(value) {
            value = Math.abs(value);
            return value + '%';
        },

        // RETURN TOKEN
        accessToken() {
            let token = store.getters.getToken;
            return token;
        },

        // RETURN USER LOGGED
        loggedUser() {
            let user = store.getters.getUser;
            return user;
        },
        
        processAwardName(award_name){
            let name = ''

            switch (award_name) {
                case 'primeiro':
                    name = '1º'
                    break;
                case 'segundo':
                    name = '2º'
                    break;
                case 'terceiro':
                    name = '3º'
                    break;
                case 'quarto':
                    name = '4º'
                    break;
                case 'quinto':
                    name = '5º'
                    break;
                case 'sexto':
                    name = '6º'
                    break;
                case 'setimo':
                    name = '7º'
                    break;
                case 'primeiro_ao_terceiro':
                    name = '1º ao 3º'
                    break;
                case 'primeiro_ao_quinto':
                    name = '1º ao 5º'
                    break;
                case 'primeiro_ao_setimo':
                    name = '1º ao 7º'
                    break;
                case 'primeiro_e_segundo':
                    name = '1º e 2º'
                    break;
                case 'sexto_e_setimo':
                    name = '6º e 7º'
                    break;
                default:
                    name = award_name
                    break;
            }

            return name;
        },

        isCombined(game) {
            let combined = false;
            let numbers = JSON.parse(game.selected_numbers)

            if(['Duque de Dezena', 'DD c/ repetição', 'Duque de Grupo', 'DG c/ repetição', 'Passe'].includes(game.modality_name)) {  
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i].length > 5) {
                        combined = true
                        break
                    }
                }
            }

            if(['Terno de Dezena', 'TDZ c/ repetição', 'Terno de grupo', 'Terno de Grupo chaveado', 'TG c/ repetição'].includes(game.modality_name)) {
                for (let i = 0; i < numbers.length; i++) {
                    if (numbers[i].length > 8) {
                        combined = true
                        break
                    }
                }
            }

            return combined ? ' Combinado' : ''
        },

        processPositionName(position){

            switch (position) {
                case 'Esquerda':
                    return  'E'

                case 'Direita':
                    return  'D'

                case 'Meio':
                    return  'M'
                default:
                    return ''
            }
        },

        animalList() {
            return [
                {
                    name: "Avestruz",
                    icon: "ostrich",
                    banner: "avestruz",
                    dezenas: ['01', '02', '03', '04'],
                    grupo: "01"
                },
                {
                    name: "Aguia",
                    icon: "eagle",
                    banner: "aguia",
                    dezenas: ['05', '06', '07', '08'],
                    grupo: "02"
                },
                {
                    name: "Burro",
                    icon: "donkey",
                    banner: "burro",
                    dezenas: ['09', '10', '11', '12'],
                    grupo: "03"
                },
                {
                    name: "Borboleta",
                    banner: "borboleta",
                    icon: "butterfly",
                    dezenas: ['13', '14', '15', '16'],
                    grupo: "04"
                },
                {
                    name: "Cachorro",
                    banner: "cachorro",
                    icon: "dog",
                    dezenas: ['17', '18', '19', '20'],
                    grupo: "05"
                },
                {
                    name: "Cabra",
                    banner: "cabra",
                    icon: "goat",
                    dezenas: ['21', '22', '23', '24'],
                    grupo: "06"
                },
                {
                    name: "Carneiro",
                    banner: "carneiro",
                    icon: "ram",
                    dezenas: ['25', '26', '27', '28'],
                    grupo: "07"
                },
                {
                    name: "Camelo",
                    banner: "camelo",
                    icon: "camel",
                    dezenas: ['29', '30', '31', '32'],
                    grupo: "08"
                },
                {
                    name: "Cobra",
                    banner: "cobra",
                    icon: "cobra",
                    dezenas: ['33', '34', '35', '36'],
                    grupo: "09"
                },
                {
                    name: "Coelho",
                    banner: "coelho",
                    icon: "rabbit",
                    dezenas: ['37', '38', '39', '40'],
                    grupo: "10"
                },
                {
                    name: "Cavalo",
                    banner: "cavalo",
                    icon: "horse",
                    dezenas: ['41', '42', '43', '44'],
                    grupo: "11"
                },
                {
                    name: "Elefante",
                    icon: "elephant",
                    banner: "elefante",
                    dezenas: ['45', '46', '47', '48'],
                    grupo: "12"
                },
                {
                    name: "Galo",
                    icon: "galo",
                    banner: "galo",
                    dezenas: ['49', '50', '51', '52'],
                    grupo: "13"
                },
                {
                    name: "Gato",
                    icon: "cat",
                    banner: "gato",
                    dezenas: ['53', '54', '55', '56'],
                    grupo: "14"
                },
                {
                    name: "Jacare",
                    icon: "crocodile",
                    banner: "jacare",
                    dezenas: ['57', '58', '59', '60'],
                    grupo: "15"
                },
                {
                    name: "Leao",
                    icon: "lion",
                    banner: "leao",
                    dezenas: ['61', '62', '63', '64'],
                    grupo: "16"
                },
                {
                    name: "Macaco",
                    icon: "monkey",
                    banner: "macaco",
                    dezenas: ['65', '66', '67', '68'],
                    grupo: "17"
                },
                {
                    name: "Porco",
                    icon: "pig",
                    banner:"porco",
                    dezenas: ['69', '70', '71', '72'],
                    grupo: "18"
                },
                {
                    name: "Pavao",
                    icon: "peacock",
                    banner: "pavao",
                    dezenas: ['73', '74', '75', '76'],
                    grupo: "19"
                },
                {
                    name: "Peru",
                    icon: "turkey",
                    banner: "peru",
                    dezenas: ['77', '78', '79', '80'],
                    grupo: "20"
                },
                {
                    name: "Touro",
                    icon: "bull",
                    banner: "touro",
                    dezenas: ['81', '82', '83', '84'],
                    grupo: "21"
                },
                {
                    name: "Tigre",
                    icon: "tiger",
                    banner: "tigre",
                    dezenas: ['85', '86', '87', '88'],
                    grupo: "22"
                },
                {
                    name: "Urso",
                    icon: "bear",
                    banner: "urso",
                    dezenas: ['89', '90', '91', '92'],
                    grupo: "23"
                },
                {
                    name: "Veado",
                    icon: "deer",
                    banner: "veado",
                    dezenas: ['93', '94', '95', '96'],
                    grupo: "24"
                },
                {
                    name: "Vaca",
                    icon: "cow",
                    banner: "vaca",
                    dezenas: ['97', '98', '99', '00'],
                    grupo: "25"
                }
            ]
        },

        getAnimal(grupo) {
            const animals = this.animalList()
            const animal = animals.find(animal => animal.grupo == grupo);
            
            return animal
        },

        
        getMenuItems() {
            var items = [
                {label: "Dashboard", icon: "fa-regular fa-chart-line", to: "/dashboard", name: 'dashboard'},
                {label: "Descarregar", icon: "fa-regular fa-share-from-square", to: "/discharges/send", name: "discharges_send"},
                {
                    label: "Cadastro", icon: "fa-regular fa-folder-plus", name: "register",
                    items: [
                        {label: "Usuários", to: "/register/user", name: "register_user"},
                        {label: "Regiões", to: "/register/region", name: "register_region"},
                        {label: "Seções", to: "/register/session", name: "register_session"},
                        {label: "Pontos de Venda", to: "/register/point", name: "register_point"},
                        {label: "Cotadas", to: "/register/quoted", name: "register_quoted"},
                        {label: "Categorias de Despesas", to: "/register/category", name: "register_category"},
                        {label: "Vinculo de região", to: "/register/link_region", name: "link_region"},
                    ],
                },
                {
                    label: "Financeiro", icon: "fa-regular fa-sack-dollar", name: "financial",
                    items: [
                        {label: "Cobrança", to: "/financial/income", name: "financial_income"},
                        {label: "Recolhe", to: "/financial/retreat", name: "financial_retreat"},
                        {label: "Despesa de Seção", to: "/financial/session_expense", name: "financial_session_expense"},
                        {label: "Despesa de Ponto", to: "/financial/point_expense", name: "financial_point_expense"},
                        {label: "Agendamento de despesas", to: "/financial/expense_schedule", name: "financial_expense_schedule"},
                    ],
                },
                {
                    label: "Bilhetes", icon: "fa-regular fa-files", name: "tickets",
                    items: [
                        {label: "Todos Bilhetes", to: "/tickets/all", name: "tickets_all"},
                        {label: "Autenticados", to: "/tickets/authenticated", name: "tickets_authenticated"},
                        {label: "Vencidos", to: "/tickets/expired", name: "tickets_expired"},
                    ],
                },
                {
                    label: "Relatórios", icon: "fa-regular fa-file-signature", name: "reports",
                    items: [
                        {label: "Bichos atrasados", to: "/report/late_animals", name: "report_late_animals"},
                        {label: "Extração", to: "/report/extraction", name: "report_extraction"},
                        {label: "Extração Por Ponto", to: "/report/point_extraction", name: "report_point_extraction"},
                        {label: "Por Região", to: "/report/region", name: "report_region"},
                        {label: "Por Seção", to: "/report/session", name: "report_session"},
                        {label: "Por Ponto", to: "/report/point", name: "report_point"},
                        {label: "Por Operador", to: "/report/operator", name: "report_operator"},
                        {label: "Por Dia", to: "/report/daily", name: "report_daily"},
                        {label: "Cancelamentos", to: "/report/cancelations", name: "report_cancelation"},
                    ],
                },
                {
                    label: "Estatísticas", icon: "fa-regular fa-chart-pie", name: "/statistics",
                    items: [
                        {label: "Por Modalidade", to: "/statistics/modality", name: "statistics_modality"},
                        {label: "Por Produto", to: "/statistics/product", name: "statistics_product"},
                        {label: "Por Região", to: "/statistics/region", name: "statistics_region"},
                        {label: "Por Seção", to: "/statistics/session", name: "statistics_session"},
                        {label: "Por Ponto", to: "/statistics/point", name: "statistics_point"},
                        {label: "Por Extração", to: "/statistics/extraction", name: "statistics_extraction"},
                        {label: "Por Extração diário", to: "/statistics/daily_extraction", name: "statistics_daily_extraction"},
                    ],
                },
                {
                    label: "Lançamentos", icon: "fa-regular fa-envelope-open-dollar", name: "/entry",
                    items: [
                        {label: "Resultado", to: "/entry/result", name: "entry_result"},
                        {label: "Reclamação", to: "/entry/claim", name: "entry_claim"},
                        {label: "Mensagens", to: "/entry/messages", name: "entry_messages"},
                    ],
                },
                {
                    label: "Bolo de Dezenas", icon: "fa-regular fa-circle-7", name: "/bolao",
                    items: [
                        {label: "Criar Rodada", to: "/bolao/round_config", name: "bolao_round_config"},
                        {label: "Relatório de Rodada", to: "/bolao/round_details", name: "bolao_round_details"},
                        {label: "Relatório por Região", to: "/bolao/new_round_groups", name: "bolao_new_round_groups"},
                        {label: "Relatório de Grupo", to: "/bolao/round_groups", name: "bolao_round_groups"},
                        {label: "Relatório Geral", to: "/bolao/point", name: "bolao_point"},
                        {label: "Mapa", to: "/bolao/map", name: "bolao_map"},
                        {label: "Cobrança", to: "/bolao/income", name: "bolao_income"},
                    ],
                },
                {
                    label: "Descarga", icon: "fa-regular fa-paper-plane", name: "/discharges",
                    items: [
                        {label: "Descarregar", to: "/discharges/send", name: "discharges_send"},
                        {label: "Descarga Manual", to: "/discharges/manual", name: "discharges_manual"},
                        {label: "Resumo", to: "/discharges/summary", name: "discharges_summary"},
                        {label: "Bilhetes", to: "/discharges/tickets", name: "discharges_tickets"},
                        {label: "Palpites por Extração", to: "/discharges/extraction_tips", name: "discharges_extraction_tips"},
                        {label: "Milhar Data", to: "/discharges/date_summary", name: "discharges_date_summary"},
                        {label: "Relatório Geral", to: "/discharges/overview", name: "discharges_overview"},
                        {label: "Configurar Limites", to: "/discharges/limits", name: "discharges_limits"},
                        {label: "Configuração de Regiões", to: "/discharges/targets", name: "discharges_targets"},
                    ],
                },
                {
                    label: "Área de afiliados", icon: "fa-regular fa-user-check", name: "/affiliates",
                    items: [
                        {label: "Afiliados", to: "/affiliates/users", name: "affiliate_users"},
                        {label: "Dashboard", to: "/affiliates/dashboard", name: "affiliate_dashboard"},
                        {label: "Comissões", to: "/affiliates/comissions", name: "affiliate_comissions"},
                        {label: "Lançamentos", to: "/affiliates/entries", name: "affiliate_entries"},
                        {label: "Fechamentos", to: "/affiliates/closures", name: "affiliate_closures"},
                    ],
                },
                {
                    label: "Online", icon: "fa-regular fa-globe-americas", name: "/online",
                    items: [
                        {label: "Dashboard", to: "/online/dashboard", name: "online_dashboard"},
                        {label: "Comissões de afiliados", to: "/online/affiliate_comissions", name: "affiliate_comissions"},
                        {label: "Solicitações", to: "/online/solicitations", name: "online_solicitations"},
                        {label: "Cadastro", to: "/online/user", name: "online_users"},
                        {label: "Bilhetes", to: "/online/tickets", name: "online_tickets"},
                        {label: "Extrato", to: "/online/transactions", name: "online_transactions"},
                        {label: "Marketing", to: "/online/marketing", name: "online_marketing"},
                        {label: "Relatório", to: "/online/reports", name: "online_reports"},
                        {label: "Despesas", to: "/online/expenses", name: "online_expenses"},
                        {label: "Bônus", to: "/online/bonus", name: "online_bonus"},
                        {label: "Banner", to: "/online/banner", name: "online_banner"},
                        {label: "Prêmios", to: "/online/prize_log", name: "online_prize_log"},
                        {label: "Artigos", to: "/online/articles", name: "online_articles"},
                        {label: "Categorias de Artigos", to: "/online/article_categories", name: "online_article_categories"},
                        {label: "Regulamento", to: "/online/regulation", name: "online_regulation"},
                        {label: "Configurações", to: "/online/config", name: "online_config"},
                    ],
                },
                {
                    label: "Slots", icon: "fa-regular fa-dice", name: "/slots",
                    items: [
                        {label: "Jogos", to: "/slots/games", name: "slot_games"},
                        {label: "Coleções", to: "/slots/collections", name: "slot_collections"},
                        {label: "Campanhas", to: "/slots/campaigns", name: "slot_campaigns"},
                        {label: "Relatório geral", to: "/slots/report", name: "slot_report"},
                        {label: "Relatório de jogos", to: "/slots/games_report", name: "games_report"},
                        {label: "Relatório de provedores", to: "/slots/providers_report", name: "providers_report"},
                    ],
                },
                {
                    label: "Rifas", icon: "fa-regular fa-ticket", name: "/prize_draws",
                    items: [
                        {label: "Criar rifa", to: "/prize_draws/rifas", name: "rifas"},
                        {label: "Bilhetes", to: "/prize_draws/tickets", name: "bilhetes rifa"},
                        {label: "Relatório Geral", to: "/prize_draws/report", name: "rifa_report"},
                    ],
                },
                {
                    label: "Configurações", icon: "fa-regular fa-gear", name: "/config",
                    items: [
                        {label: "Guia de Pagamento", to: "/config/payment_guide", name: "config_payment_guide"},
                        {label: "Modalidades", to: "/config/modality", name: "config_modality"},
                        {label: "Produtos", to: "/config/product", name: "config_product"},
                        {label: "Região", to: "/config/region", name: "config_region"},
                        {label: "Horário de loterias", to: "/config/lottery_schedule", name: "config_lottery_schedule"},
                        {label: "Extração", to: "/config/extraction", name: "config_extraction"},
                        {label: "Rodapé", to: "/config/ticket_attachment", name: "config_ticket_attachment"},
                        {label: "Alterar Senha", to: "/config/change_password", name: "config_change_password"},
                        {label: "Configurar Menu", to: "/config/menu", name: "config_menu"},
                    ],
                },
                {label: "Auditoria", icon: "fa-regular fa-memo-pad", to: "/logs", name: "logs"},
            ]

           
            return items
        },

        updateMenuItems(menuItems, userConfig) {
            menuItems.forEach((menuItem) => {
                const matchingConfig = userConfig.find((config) => config.name === menuItem.name);
                menuItem.selected = matchingConfig ? matchingConfig.selected === 1 : false;

                if (menuItem.items) {
                    menuItem.items = this.updateMenuItems(menuItem.items, userConfig);
                }
            })

            return menuItems;
        },
    },
}